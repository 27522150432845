@import "../../../Root/common.scss";

.boxes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .outside {
    position: relative;
    width: 90%;
    height: 300px;
    margin-top: 20px;
    box-shadow: 10px 10px 25px $first-color;
    border-radius: 10px;
    text-align: center;

    a {
      color: $text-second-color;
      text-decoration: none;

      &:hover div {
        opacity: 0.9;
      }

      div {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 10px;
        background-color: black;
        opacity: 0.7;
        border-radius: 10px;
        transition: 0.3s;

        h2 {
          text-transform: uppercase;
          margin-bottom: 20px;
        }

        h3 {
          margin-top: 15px;
        }

        .sentence {
          display: flex;

          p:nth-child(2) {
            align-self: flex-end;
            margin-top: 25px;
            font-style: italic;
          }
        }
      }

      button {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
    }
  }

  .outside:nth-child(1) {
    background-image: url("../../../assets/images/box1_640.jpg");
    background-position: center;
  }

  .outside:nth-child(2) {
    background-image: url("../../../assets/images/box2_640.jpg");
    background-position: center;
  }

  .outside:nth-child(3) {
    background-image: url("../../../assets/images/box3_640.jpg");
    background-position: center;
    background-size: cover;
  }

  .outside:nth-child(4) {
    background-image: url("../../../assets/images/candles_640.jpg");
    background-position: center;
    background-size: cover;
  }

  .outside:nth-child(5) {
    background-image: url("../../../assets/images/box4_640.jpg");
    background-position: center;
    background-size: cover;
  }

  .outside:nth-child(6) {
    background-image: url("../../../assets/images/box5_640.jpg");
    background-position: center;
    background-size: cover;

    div {
      position: absolute;
      top: 0;
      background-color: transparent;
      opacity: 1;

      h2 {
        background-color: black;
        opacity: 0.75;
      }
    }

    .slider {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      .image {
        position: absolute;
        top: 0;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        transition: 2s;
        background-position: center;
        background-size: cover;
      }
    }
  }

  .outside:nth-child(7) {
    background-image: url("../../../assets/images/73.jpg");
    background-position: center;
    background-position-y: -80px;
    background-size: cover;
  }

  .outside:nth-child(8) {
    background-image: url("../../../assets/images/hand_640.jpg");
    background-position: center;
    background-size: cover;
  }

  @media screen and (min-width: map-get($breakpoins, lm)) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;


    .outside {
      width: 40%;
      height: 350px;
    }


  }

  @media screen and (min-width: map-get($breakpoins, xl)) {
    // justify-content: space-between;

    .outside {
      width: 30%;
      height: 300px;
    }
  }

  @media screen and (min-width: map-get($breakpoins, xx)) {
    // justify-content: space-between;

    .outside {
      width: 26%;
    }
  }
}
@import "../../Root/common.scss";

.wrapper {
    width: 100%;
    min-height: 40vh;

    .inside {

        width: 85%;
        margin: 40px auto;

        h1 {
            text-align: center;
            color: $third-color;
            margin-bottom: 40px;
        }

        .links {
            display: flex;
            flex-direction: column;

            a {
                margin-bottom: 20px;
                text-decoration: none;
                color: $third-color;
                font-weight: 700;
                transition: 0.3s;
                &:hover {
                    color: $fifth-color;
                }
            }
        }

    }
}
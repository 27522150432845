@import "../../Root/common.scss";

.wrapper {
  width: 100%;

  .image {
    width: 100%;
    height: 250px;
    background-image: url("../../assets/images/hands_640.jpg");
    background-size: cover;
    background-position: center;
  }

  .inside {
    width: 85%;
    margin: 40px auto;

    h1 {
      margin: 40px auto;
      color: $third-color;
      text-align: center;
    }

    h3 {
      margin: 30px auto 10px;
    }
  }

  @media screen and (min-width: map-get($breakpoins, lm)) {
    .image {
      height: 350px;
    }
  }

  @media screen and (min-width: map-get($breakpoins, xl)) {
    .image {
      height: 450px;
      background-image: url("../../assets/images/hands_1280.jpg");

    }
  }
}
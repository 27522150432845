@import "../../Root/common.scss";
.wrapper {
  position: fixed;
  bottom: 40px;
  right: 40px;

  .icon {
    width: 50px;
    height: 50px;
    background-image: url("../../assets/images/baseline_arrow_circle_up_black_24dp.png");
    background-size: cover;
    background-position: center;
    cursor: pointer;
  }
}

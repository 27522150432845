@import "../../Root/common.scss";

.wrapper {
  width: 100%;

  .image {
    width: 100%;
    height: 280px;
    background-image: url("../../assets/images/picture_four_640.jpg");
    background-size: cover;
    background-position: center;
  }

  .special {
    margin: 40px auto 0 auto;
    display: flex;
    justify-content: center;

    a {
      font-size: $l-font-size;
      color: $worning-color;
      font-weight: 700;
      text-decoration: none;
      text-align: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .inside {
    width: 85%;
    margin: 40px auto;

    .bigInfo {
      width: 50%;
      display: flex;
      justify-content: center;
      margin: 50px auto;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid $third-color;
      cursor: pointer;
      transition: 0.35s;

      a {
        font-size: $l-font-size;
        color: $third-color;
        text-decoration: none;
        text-align: center;
      }

      &:hover {
        background-color: $first-color;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoins, lm)) {
    .image {
      height: 350px;
    }

    .inside {
      .bigInfo {
        width: 30%;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoins, xl)) {
    .image {
      height: 520px;
      background-image: url("../../assets/images/picture_four_1280.jpg");

    }

    .bigInfo {
      width: 20%;
    }
  }
}
@import "../../Root/common.scss";
.modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  backdrop-filter: blur(2px);
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    z-index: 20;
    border: none;
    border: 2px solid black;
    background-color: white;
    color: black;
    border-radius: 5px;
  }
}

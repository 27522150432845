@import "../../Root/common.scss";

.wrapper {
    width: 90vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h2 {
        margin: 20px auto;
        text-align: center;
        color: $third-color;
    }

    .priest {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;


        .name {
            margin-bottom: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;

            h3,
            p,
            span {
                flex-basis: 100%;
                color: $third-color;
            }
        }

        .info {
            flex-basis: 100%;

            p {
                margin-bottom: 10px;
                font-size: $s-font-size;

                span {
                    font-weight: 700;
                }
            }

            ul {
                margin-bottom: 10px;

                li {
                    margin-left: 20px;
                    font-size: $s-font-size;
                }
            }

        }
    }

    @media screen and (min-width: map-get($breakpoins, xl)) {
        .priest {
            .info {

                p,
                ul {

                    font-size: $m-font-size/1.2;

                    li {
                        font-size: $m-font-size/1.2;
                    }
                }
            }
        }
    }

    @media screen and (min-width: map-get($breakpoins, xx)) {
        .priest {
            .info {

                p,
                ul {

                    font-size: $m-font-size/1.1;

                    li {
                        font-size: $m-font-size/1.1;
                    }
                }
            }
        }
    }
}
.wrapper {
    min-height: 50vh;
    width: 90%;
    margin: 0 auto;

    .inside {

        .links {
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}
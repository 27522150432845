@import "../../../Root/common.scss";

.wrapper {
  width: 100%;
  .slider {
    position: relative;
    width: 100%;
    min-height: 95vh;
    overflow: hidden;
    .image {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      transition: 2s;
      .news {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        width: 90%;
        height: auto;
        padding: 20px;
        color: $second-color;
        opacity: 0.8;
        background-color: black;
        h2 {
          margin-bottom: 20px;
          padding-bottom: 10px;
          border-bottom: 1px solid $first-color;
        }
      }
    }
    .left {
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      background-color: white;
      border-radius: 50%;
      opacity: 0.8;
      background-image: url("../../../assets/images/arrow_left.png");
      background-size: cover;
      background-position: center;
      background-position-x: 10px;
      z-index: 10;
      cursor: pointer;
      &:hover {
        background-color: $first-color;
      }
      @media screen and (min-width: map-get($breakpoins, lm)) {
        left: 2%;
      }
    }
    .right {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      background-color: white;
      border-radius: 50%;
      opacity: 0.8;
      background-image: url("../../../assets/images/arrow_right.png");
      background-size: cover;
      background-position: center;
      z-index: 10;
      cursor: pointer;
      &:hover {
        background-color: $first-color;
      }
      @media screen and (min-width: map-get($breakpoins, lm)) {
        right: 2%;
      }
    }
  }
  @media screen and (min-width: map-get($breakpoins, lm)) {
    .slider {
      min-height: 85vh;
    }
    p {
      font-size: $l-font-size;
    }
  }
}
.none {
  display: none;
}
.image:nth-child(4) {
  height: 100%;
  background-image: url("../../../assets/images/main1_640.jpg");
  background-position: center;
  background-size: cover;
  @media screen and (min-width: map-get($breakpoins, xl)) {
    background-image: url("../../../assets/images/main1_1280.jpg");
  }
}
.image:nth-child(2) {
  height: 100%;
  background-image: url("../../../assets/images/main2_640.JPG");
  background-position: center;
  background-size: cover;
  @media screen and (orientation: landscape) {
    background-image: url("../../../assets/images/main2_640.JPG");
    background-position-y: -70px;
  }
  @media screen and (min-width: map-get($breakpoins, xl)) {
    background-image: url("../../../assets/images/main2_1280.JPG");
    background-position-y: -130px;
  }
}
.image:nth-child(3) {
  height: 100%;
  background-image: url("../../../assets/images/main3_640.jpg");
  background-position: center;
  background-size: cover;
  @media screen and (min-width: map-get($breakpoins, xl)) {
    background-image: url("../../../assets/images/main3_1280.jpg");
  }
}
.image:nth-child(1) {
  height: 100%;
  background-image: url("../../../assets/images/main4.jpg");
  background-position: center;
  background-size: cover;

  @media screen and (min-width: map-get($breakpoins, xl)) {
    background-image: url("../../../assets/images/main4.jpg");
  }
}

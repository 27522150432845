$first-color: #d9b01c;
$second-color: #fff6ee;
$third-color: #3d2605;
$fifth-color: #339fcd;
$worning-color: rgb(161, 7, 7);

$text-first-color: rgb(0, 0, 0);
$text-second-color: #fff;
$text-third-color: #aaa;

$s-font-size: 14px;
$m-font-size: 18px;
$l-font-size: 22px;
$x-font-size: 28px;

$inside-width: 85%;

$breakpoins: (
  xs: 340px,
  sm: 480px,
  sl: 640px,
  lm: 800px,
  xl: 1020px,
  xx: 1280px,
);

@import "../../Root/common.scss";

.wrapper {
  width: 85%;
  margin: 20px auto;

  h2 {
    text-align: center;
    color: $third-color;
  }

  h3 {
    margin-bottom: 20px;
    color: $third-color;
  }

  h4 {
    text-align: right;
    color: $third-color;
    font-style: italic;
  }

  p {
    margin: 20px auto;

    span {
      font-weight: 700;
    }
  }

  ul{
    margin-left: 30px;
  }

  .italic {
    font-style: italic;
    text-align: right;
  }

  .images {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .imageItem {
      flex-basis: 90%;
      height: 200px;
      margin-top: 10px;
      border: 1px solid $first-color;
      border-radius: 10px;
      background-position: center;
      background-size: auto;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }

  @media screen and (min-width: map-get($breakpoins, sm)) {
    .images {
      .imageItem {
        height: 280px;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoins, sl)) {
    .images {
      justify-content: space-around;

      .imageItem {
        flex-basis: 45%;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoins, lm)) {
    .images {
      .imageItem {
        height: 320px;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoins, xx)) {
    .images {
      .imageItem {
        flex-basis: 32%;
      }
    }
  }
}
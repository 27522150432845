@import "../../Root/common.scss";

.wrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    h2 {
        margin: 20px auto;
        text-align: center;
        color: $third-color;
    }

    @media screen and (min-width: map-get($breakpoins, xl)) {
        flex-direction: row;
        flex-wrap: wrap;

        h2 {
            flex-basis: 90%;
        }
    }
}
@import "../../Root/common.scss";

.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: black;
  opacity: 0.9;
  .inside {
    width: 80%;
    margin: 10px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .info {
      margin-bottom: 15px;
      text-align: center;
      p {
        color: white;
        a {
          color: red;
        }
      }
    }
    .image {
      width: 30px;
      height: 30px;
      background-color: white;
      background-image: url("../../assets/images/baseline_cancel_black_24dp.png");
      background-position: center;
      cursor: pointer;
    }
    button {
      font-size: 14px;
    }
  }
  @media screen and (min-width: map-get($breakpoins, lm)) {
    height: 120px;
  }
}

@import "../../../Root/common.scss";

.newsWrapper {
  width: 90%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
  }

  h2 {
    text-align: center;
    color: $third-color;
    margin-bottom: 30px;
    flex-basis: 100%;
  }

  .newsItem {
    width: 300px;
    height: auto;
    border-radius: 10px;
    box-shadow: 10px 10px 25px $third-color;
    padding: 10px;
    margin-bottom: 30px;
    .newsTitle {
      h5 {
        text-align: right;
        text-decoration: underline;
      }
      h3 {
        text-align: center;
        text-decoration: underline;
        margin: 20px;
      }
      h3,
      h5, p {
        color: $third-color;
      }

      p{
        text-align: center;
        margin-bottom: 10px;
        font-size: small;

        span {
          font-weight: 700;
        }
      }
    }

    .img {
      width: 200px;
      margin: 0 auto;
      height: 0;
      padding-bottom: 50%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      border: 1px solid $third-color;
      border-radius: 10px;
    }

    .img1 {
      background-image: url("../../../assets/newsItems/organy.jpg");
      width: 155px;
    }
    .img2 {
      background-image: url("../../../assets/newsItems/piesz_pielg_tarnows.png");
      width: 135px;
      height: 100px;
    }

 }

  @media screen and (min-width: map-get($breakpoins, sl)) {
    flex-direction: row;
    flex-wrap: wrap;

    .newsItem {
      margin-right: 20px;
    }
  }

  @media screen and (min-width: map-get($breakpoins, xl)) {
    h2 {
      font-size: $x-font-size * 1.3;
    }

    .newsTitle {
      h3 {
        font-size: $x-font-size * 0.7;
      }
    }
  }
  @media screen and (min-width: map-get($breakpoins, xx)) {
    h2 {
      font-size: $x-font-size * 2;
    }

    .newsItem {
      margin-right: 100px;
    }

    .newsTitle {
      h3 {
        font-size: $x-font-size * 0.9;
      }
    }
  }
}

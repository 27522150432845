@import "../../../Root/common.scss";

.wrapper {
  width: 100%;

  .inside {
    width: 85%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .element {
      width: 90%;
      min-height: 240px;
      margin-bottom: 20px;
      border: 1px solid $first-color;
      border-radius: 10px;

      .image {
        width: 100%;
        height: 100px;
        border-radius: 10px;
        background-position: center;
        background-size: cover;
      }

      .info {
        width: 80%;
        margin: 10px auto;

        h4 {
          text-align: center;
        }

        p {
          font-size: $s-font-size;
        }
      }

      a {
        display: block;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-bottom: 20px;

        button {
          font-size: $s-font-size/1.2;
        }
      }
    }



  }

  @media screen and (min-width: map-get($breakpoins, lm)) {
    .inside {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      .element {
        width: 45%;
      }
    }
  }

  @media screen and (min-width: map-get($breakpoins, xl)) {
    .inside {
      .element {
        width: 30%;

        .image {
          height: 150px;
        }
      }
    }
  }
}
@import "../../Root/common.scss";

.wrapper {
  width: 100%;
  height: 100px;
  background-color: $second-color;



  .inside {
    position: relative;
    width: $inside-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 50px;
      height: 50px;
      background-image: url("../../assets/images/logo1.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-top: 10px;
    }

    .navigation {
      background-color: $second-color;

      .burger {
        width: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $first-color;
        border-radius: 5px;
        padding: 5px 10px;
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background-color: $first-color;
        }
      }

      nav {
        width: 100%;
        position: absolute;
        // top: 70px;
        top: -740px;
        left: 0;
        background-color: $second-color;
        border-top: 2px solid $third-color;
        transition: 0.5s;
        z-index: 10;

        ul {
          display: flex;
          flex-direction: column;
          align-items: center;

          a {
            width: 140px;
            margin: 20px auto;
            padding: 5px 7px;
            color: $third-color;
            text-decoration: none;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            border-radius: 5px;
            transition: 0.3s;

            &:hover {
              background-color: $third-color;
              color: $fifth-color;
            }
          }
        }
      }

      .isVisible {
        top: 100px;
      }
    }
  }

  @media screen and (orientation:landscape) {
    .inside {
      .navigation {
        nav {
          ul {
            flex-direction: row;
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  @media screen and (min-width: map-get($breakpoins, xx)) {
    height: 100px;

    .inside {
      width: 100%;

      .logo {
        width: 90px;
        height: 85px;
      }

      .navigation {
        .burger {
          display: none;
        }

        nav {
          position: relative;
          top: 0;
          border-top: none;

          ul {
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            a {
              width: 9vw;
              font-weight: normal;
              font-size: $s-font-size;

              &:hover {
                background-color: transparent;
                color: $fifth-color;
              }
            }
          }
        }
      }
    }
  }

}

.isVisible {
  top: 70px;
}